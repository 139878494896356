@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap');

html,
body {
    background-color: #050505;
    margin: 0;
    font-family: 'Roboto Mono', monospace;
    overflow-x: hidden !important;
}

canvas {
    display: block !important;
    position: fixed !important;
    overflow-x: hidden !important;
}

.container {
    padding: 10vh 5vw;
    position: absolute;
    z-index: 2;
    max-width: 700px;
}

#canvas-container {
    position: fixed;
    z-index: 1;
}

.header {
    font-size: 2.5em;
}

.intro {
    font-size: 1.1em;
}

.link-1 {
    font-size: 1.3em;
}

.link-1-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    max-width: 300px;
    padding: 0px 0px 20px 0px;
    margin-bottom: 30px;
}

.link-2 {
    font-size: 1.3em;
}

.link-2-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -10px;
}

.link-3 {
    font-size: 1em;
}

.link-3-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    padding: 40px 0px;
}

.primary-text {
    color: #c8c8c8;
    font-weight: 500;
    line-height: 160%;
}

.sub-text {
    color: #807f7f;
    font-weight: 400;
    line-height: 180%;
    filter: invert(100%);
}

.sub-text2 {
    color: #bababa;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
    filter: invert(100%);
}

.sub-text>a {
    color: #818181;
    display: inline-block;
    text-decoration: none;
    width: fit-content;
}

.sub-text>a:hover {
    filter: brightness(0.6);
}

/* .yodaBox:hover {
    position: relative;
    transition: .5s;
}

.yodaBox:hover:after {
    content: url(assets/images/dako.png);
    display: block;
    position: absolute;
    left: 200px;
    top: -40px;
    filter: brightness(0.7);
}

.yodaBox:hover ~ canvas {
    display: none;
} */

.arrow {
    margin-left: 8px;
}

.footer {
    font-size: .8em;
}

@media screen and (max-width: 1400px) {
    .container {
        max-width: 50vw;
    }
}

@media screen and (max-width: 1150px) {
    .container {
        max-width: 60vw;
    }

    canvas {
        position: fixed;
    }
}

@media screen and (max-width: 950px) {
    .container {
        max-width: 70vw;
    }
}

@media screen and (max-width: 780px) {
    .container {
        max-width: 80vw;
    }
}

@media screen and (max-width: 650px) {
    .container {
        padding: 5vh 5vw;
        max-width: 70vw;
    }

    .header {
        font-size: 2em;
    }

    .intro {
        font-size: 1em;
    }

    .link-1 {
        font-size: 1em;
    }

    .link-1-container {
        width: 70%;
        padding: 20px 0px;
    }

    .link-2 {
        font-size: 1em;
    }

    .link-2>a {
        padding-top: 10px;
    }

    .link-3 {
        font-size: .8em;
    }

    .link-3-container {
        width: 95%;
        padding: 30px 0px;
    }


}

@media screen and (max-width: 450px) {
    .container {
        padding: 5vh 5vw;
        min-width: 90vw;
    }

}